@import "../../variables";

.service-now-page {
  padding-top: 0;

  section {
    padding: 80px 0 0;

    > h3 {
      color: $dark-blue;
      margin-bottom: 24px;
    }
  }

  section:first-of-type {
    padding: 80px 0 0;
  }

  &__blocks {
    padding: 40px 80px;
  }

  &__info-block {
    max-width: 1120px;
    padding-right: 380px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  &__menu-block {
    width: 265px;
    position: fixed;
    top: 120px;
    right: calc((100% - 1120px)/2);
  }

  &__main {
    h2, p {
      margin-bottom: 24px;
    }

    video {
      width: 100%;
      height: auto;
    }
  }

  &__products {
    p {
      margin-bottom: 24px;
    }
  }

  &__training {
    h3, p {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 1280px) {
  .service-now-page {
    &__menu-block {
      right: 80px;
    }
  }
}

@media (max-width: 1024px) {
  .service-now-page {
    &__blocks {
      padding: 40px;
    }

    &__menu-block {
      display: none;
    }

    &__info-block {
      width: 100%;
      padding-right: 0;
    }
  }
}

@media (max-width: 480px) {
  .service-now-page {
    section {
      padding: 56px 0 0;
    }

    &__blocks {
      padding: 40px 24px;
    }
  }
}

