@import "../../variables";

.services-now-training-page {
  padding-top: 0;

  section {
    padding: 80px 0 0;

    > h3 {
      color: $dark-blue;
      margin-bottom: 24px;
    }
  }

  section:first-of-type {
    padding: 80px 0 0;
  }

  &__blocks {
    padding: 40px 80px;
  }

  &__info-block {
    max-width: 1120px;
    padding-right: 380px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  &__menu-block {
    width: 265px;
    position: fixed;
    top: 120px;
    right: calc((100% - 1120px)/2);
  }

  &__main,
  &__about,
  &__people,
  &__place,
  &__reasons,
  &__program {
    p {
      margin-bottom: 24px;
    }
  }

  &__main {
    h2 {
      margin-bottom: 24px;
    }

    &-timer {
      margin-bottom: 24px;

      .progressbar-block {
        display: flex;
        align-items: center;

        &__percent {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  &__about {
    &-block {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  &__people, &__place {
    video {
      width: 100%;
      height: auto;
    }
  }

  &__reasons {
    &-block {
      margin-bottom: 24px;
    }
  }

  &__program {
    h4 {
      font-size: 20px;
      line-height: 28px;
      color: $dark-blue;
      text-align: center;
      margin: 80px 0 48px;
    }
  }

  &__prices {
    &-block {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      div {
        padding: 40px 24px;
        background-color: $dark-blue;
        width: 50%;
        border-radius: 4px;

        h3 {
          font-size: 24px;
          line-height: 32px;
          color: $light-green;
          margin-bottom: 8px;
        }

        p {
          color: rgba($white, 0.60);
          margin-bottom: 8px;
        }

        span {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          color: $white;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .services-now-training-page {
    &__menu-block {
      right: 80px;
    }
  }
}

@media (max-width: 1024px) {
  .services-now-training-page {
    &__blocks {
      padding: 40px;
    }

    &__menu-block {
      display: none;
    }

    &__info-block {
      width: 100%;
      padding-right: 0;
    }
  }
}

@media (max-width: 480px) {
  .services-now-training-page {
    section {
      padding: 56px 0 0;
    }

    &__blocks {
      padding: 40px 24px;
    }

    &__prices {
      &-block {
        flex-direction: column;
        gap: 24px;

        div {
          width: 100%;
          box-sizing: border-box;
          padding: 24px;
        }
      }
    }
  }
}

