@import "../../variables";

.project-page {
  padding-top: 0;

  section {
    padding: 80px 0 0;

    > h3 {
      color: $dark-blue;
      margin-bottom: 24px;
    }
  }

  section:first-of-type {
    padding: 80px 0 0;
  }


  &__blocks {
    padding: 40px 80px;
  }

  &__info-block {
    max-width: 1120px;
    padding-right: 380px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  &__menu-block {
    width: 265px;
    position: fixed;
    top: 120px;
    right: calc((100% - 1120px)/2);
  }

  h2, p {
    margin-bottom: 24px;
  }

  &__main {
    &-info {
      h2 {
        margin-bottom: 0;
      }

      p {
        color: rgba($dark-gray, 0.50);
      }
    }

    img {
      width: 60%;
      max-width: 455px;
      border-radius: 8px;
      box-shadow: 16px 16px 0 0 #DDD, 0 0 10px 0 rgba(0, 0, 0, 0.24);
    }
  }

  &__product-info {
    &-block {
      padding: 40px;
      background-color: $white;
      border-radius: 8px;
      border: 1px solid $light-blue;
      display: flex;
      flex-direction: column;
      gap: 24px;

      h3 {
        color: $dark-blue;
      }

      div {
        span {
          font-weight: 700;
        }

        p {
          margin-bottom: 0;
          display: inline-block;
        }
      }
    }
  }

  &__connect {
    text-align: center;
  }
}

@media (max-width: 1280px) {
  .project-page {
    &__menu-block {
      right: 80px;
    }
  }
}

@media (max-width: 1024px) {
  .project-page {
    &__blocks {
      padding: 40px;
    }

    &__menu-block {
      display: none;
    }

    &__info-block {
      width: 100%;
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .project-page {
    &__main {
      img {
        width: 320px;
      }
    }
  }
}

@media (max-width: 480px) {
  .project-page {
    section {
      padding: 56px 0 0;
    }

    &__main {
      img {
        width: 200px;
      }
    }

    &__blocks {
      padding: 40px 24px;
    }

    &__product-info-block {
      padding: 24px;
    }
  }
}
