@import "../variables";

.service-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 24px 24px;
  text-align: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  height: 280px;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
    border-radius: 100px;
    border: 1px solid $light-blue;

    img {
      width: 32px;
      height: 32px;
    }
  }

  h3 {
    color: $white;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  &__link {
    flex-grow: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
