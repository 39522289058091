@import "../variables";

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }

  &.show .modal-dialog {
    transform: none;
  }

  .modal-dialog {
    position: relative;
    width: calc(100% - 40px);
    max-width: 500px;
    margin: 60px auto;
    pointer-events: none;

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      pointer-events: auto;
      background-color: $white;
      border-radius: 10px;
      outline: 0;
      color: $dark-gray;
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;

      .btn-close {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='%234B4E6DFF'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-color: transparent;
        border: 0;
        height: 16px;
        width: 16px;
        cursor: pointer;
      }

      .btn-close:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='%23F48B01FF'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
      }
    }

    .modal-body {
      padding: 20px 0;

      p {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 16px;
      }
    }
  }
}

.fade {
  transition: opacity 0.15s linear;
}
