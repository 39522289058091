@import "../variables";

.careers-page {
  &__main {
    padding-top: 40px;

    &-block {
      min-height: 322px;

      h4 {
        display: block;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 24px;
        color: $dark-blue;
      }

      img {
        width: 644px;
        height: 322px;
        border-radius: 8px;
        float: right;
        margin: 0 0 20px 40px;
      }
    }
  }

  &__opportunities {
    background-color: rgba(75, 78, 109, 0.08);

    h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    &-blocks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;

      .careers-opportunity-block {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background: $white;
        overflow: hidden;

        img {
          width: 100%;
        }

        & > div {
          flex-grow: 100;
          display: flex;
          flex-direction: column;
          padding: 24px;

          h4 + p {
            color: rgba($dark-gray, 50%);
            margin-bottom: 16px;
          }
        }

        &__link {
          flex-grow: 100;
          display: flex;
          align-items: flex-end;
          margin-top: 30px;
        }
      }
    }
  }

  &__connection {
    text-align: center;

    h3 {
      color: $dark-blue
    }

    p {
      margin: 24px auto;
    }
  }
}

@media (max-width: 1279px) {
  .careers-page {
    &__main {
      &-block {
        min-height: 250px;
      }

      img {
        width: 500px;
        height: 250px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .careers-page {
    &__main {

      &-block {
        min-height: 210px;
      }

      img {
        width: 420px;
        height: 210px;
      }
    }
  }
}

@media (max-width: 950px) {
  .careers-page {
    &__main {

      &-block {
        min-height: 180px;
      }

      img {
        width: 360px;
        height: 180px;
      }
    }
  }
}

@media (max-width: 768px) {
  .careers-page {
    &__main {
      padding: 40px 40px 80px;

      &-block {
        display: flex;
        flex-direction: column;
        min-height: auto;

        img {
          width: 320px;
          height: auto;
          float: none;
          margin: 24px 0 0;
          order: 1
        }
      }
    }

    &__opportunities {
      padding: 40px 40px 80px;
      &-blocks {
        .careers-opportunity-block {
          width: 100%;

          &__link {
            margin-top: 16px;
          }
        }
      }
    }

    &__connection {
      padding: 80px 40px;
    }
  }
}

@media (max-width: 480px) {
  .careers-page {
    &__main {
      padding: 40px 24px 80px;

      &-block {
        img {
          width: 100%;
        }
      }
    }

    &__opportunities {
      padding: 40px 24px 80px;
    }

    &__connection {
      padding: 80px 24px;
    }
  }
}
