@import "variables";

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;

  &:before {
    content: "";
    background-image: url('../images/background.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-color: $white;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
  }
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
  margin: 0 auto;
  white-space: pre-line;
  background-color: transparent;
}

header,
header + div,
footer {
  z-index: 1;
}

* {
  font-family: 'Manrope', 'Roboto', 'Helvetica Neue', sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $black;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $dark-gray;
}

a.active {
  color: $dark-blue;
  font-weight: 600;
}

h1 {
  font-size: 72px;
  line-height: 150%;
  font-weight: 700;
  color: $dark-blue;
}

h2 {
  font-size: 40px;
  line-height: 150%;
  font-weight: 700;
  margin-bottom: 40px;
  color: $dark-blue;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: $green;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: $green;
}

.nav-button {
  display: inline-block;
  height: auto;
  width: auto;
  padding: 8px 24px;
  background-color: $orange;
  color: $white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.cancel-button {
    background-color: rgba($light-gray, 50%);
    color: $dark-gray;
  }

  &:hover {
    background-color: $green;
    color: $white;
  }
}

.page {
  padding-top: 72px;
}

section {
  padding: 80px;
}

.section-wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
}

.nav-tabs {
  height: 68px;
  width: 100%;
  background-color: rgba($light-gray, 50%);
  border-radius: 52px;

  .nav-item {
    display: inline-block;
    width: calc(50% - 8px);
    margin: 4px;

    a, button {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 52px;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      color: $dark-gray;
      padding: 16px;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.active {
        background-color: $orange;
        color: $white;
        font-weight: 300;
      }
    }
  }
}

.dropdown {
  position: relative;

  .nav-link,
  .btn {
    display: flex;

    &:after {
      content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 15.4L6 9.4L7.075 8.325L12 13.25L16.925 8.325L18 9.4L12 15.4Z' fill='%234B4E6D'/%3E%3C/svg%3E");
      display: inline-block;
      width: 24px;
      height: 24px;
    }
  }

  .btn {
    background-color: transparent;
    border: none;
    height: 24px;
    cursor: pointer;
    color: $dark-gray;
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    inset: 4px 0 auto auto !important;
    padding: 8px 0;
    background-color: $white;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
    z-index: 1;

    * {
      font-weight: 300;
    }

    &.show {
      display: flex;
      flex-direction: column;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      color: $black;

      &:hover, &.active {
        background-color: rgba($light-gray, 0.50);
      }

      &.active:after {
        content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9.00003 16.17L4.83003 12L3.41003 13.41L9.00003 19L21 7L19.59 5.59L9.00003 16.17Z' fill='%234B4E6D'/%3E%3C/svg%3E");
        display: inline-block;
        width: 24px;
        height: 24px;
        padding-left: 8px;
      }
    }
  }
}

.checkbox-block {
  display: flex;

  input[type='checkbox'] {
    display: none;
  }

  label {
    display: flex;
    color: $dark-gray;

    &::before {
      content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H19.5C19.9 3 20.25 3.15 20.55 3.45C20.85 3.75 21 4.1 21 4.5V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5Z' fill='%23DDDDDD'/%3E%3C/svg%3E");
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &:hover {
      &::before {
        content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H19.5C19.9 3 20.25 3.15 20.55 3.45C20.85 3.75 21 4.1 21 4.5V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5Z' fill='%23F48B01'/%3E%3C/svg%3E");
      }
    }

    &.danger {
      &::before {
        content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H19.5C19.9 3 20.25 3.15 20.55 3.45C20.85 3.75 21 4.1 21 4.5V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5Z' fill='%23F97370'/%3E%3C/svg%3E");
      }
    }
  }

  input[type='checkbox']:checked ~ label::before {
    content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z' fill='%2384DCC6'/%3E%3C/svg%3E");
  }
}

.chips-block {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  div, button {
    padding: 9px 13px;
    border-radius: 8px;
    background-color: $white;
    border: 3px solid $light-gray;
    color: $dark-gray;
    transition: border 0.3s, background-color 0.3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.green {
      border-color: $light-green;
    }

    &.orange {
      border-color: $orange;
    }

    &.blue {
      border-color: $light-blue;
    }

    &.dark-blue {
      border-color: $dark-blue;
    }

    &:hover {
      border-color: $light-gray;
    }

    &.chip-button {
      position: relative;

      &.active {
        padding-left: 45px;

        &:before {
          content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M9.00016 16.2068L4.83016 12.0368L3.41016 13.4468L9.00016 19.0368L21.0002 7.03683L19.5902 5.62683L9.00016 16.2068Z' fill='%234B4E6D'/%3E%3C/svg%3E");
          display: block;
          height: 24px;
          width: 24px;
          position: absolute;
          left: 13px;
          top: 9px;
        }
      }
    }

    &.all-chips-button {
      position: relative;
      padding-left: 45px;

      &:before {
        content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M10 18.0369H14V16.0369H10V18.0369ZM3 6.03687V8.03687H21V6.03687H3ZM6 13.0369H18V11.0369H6V13.0369Z' fill='%234B4E6D'/%3E%3C/svg%3E");
        display: block;
        height: 24px;
        width: 24px;
        padding-right: 8px;
        position: absolute;
        left: 13px;
      }

      &.active {
        border-color: $dark-blue;
        background-color: $dark-blue;
        color: $white;

        &:before {
          content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M10 18.0369H14V16.0369H10V18.0369ZM3 6.03687V8.03687H21V6.03687H3ZM6 13.0369H18V11.0369H6V13.0369Z' fill='white'/%3E%3C/svg%3E");
        }

        &:hover {
          border-color: $light-gray;
          background-color: $light-gray;
          color: $dark-gray;

          &:before {
            content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M10 18.0369H14V16.0369H10V18.0369ZM3 6.03687V8.03687H21V6.03687H3ZM6 13.0369H18V11.0369H6V13.0369Z' fill='%234B4E6D'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  button {
    cursor: pointer;
  }
}

.list-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: rgba($dark-gray, 0.08);
  border-radius: 8px;

  h3 {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 28px;
  }

  > h3 {
    color: $dark-blue;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
  }

  h3 + p {
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    padding-left: 40px;

    &:before {
      content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M14.025 18.0238L12.95 16.9738L17.15 12.7738H4V11.2738H17.15L12.925 7.04878L14 5.99878L20.025 12.0238L14.025 18.0238Z' fill='%23366092'/%3E%3C/svg%3E");
      display: block;
      position: absolute;
      height: 24px;
      width: 24px;
      left: 0;
      top: 2px;
    }
  }
}

svg.CircularProgressbar {
  height: 48px;
  width: 48px;

  .CircularProgressbar-path {
    stroke: $light-green !important;
  }

  .CircularProgressbar-trail {
    stroke: $light-gray !important;
  }
}

.video-container {
  width: 100%;
  padding-top: 56%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .video-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 1024px) {
  section {
    padding: 80px 40px;
  }
}

@media (max-width: 480px) {
  section {
    padding: 40px 24px;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .nav-tabs {
    height: 64px;

    .nav-item {
      a, button {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
