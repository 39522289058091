@import "../variables";

.breadcrumbs {
  margin-bottom: 16px;

  &-block {
    display: flex;
    flex-wrap: wrap;

    li {
      list-style: none;
      color: $dark-gray;
      display: flex;
      margin-bottom: 8px;

      a {
        color: rgba($dark-gray, 50%);

        &.active {
          font-weight: 400;
        }
      }
    }

    li:not(:last-of-type):after {
      content: "/\00a0";
      display: block;
      color: $light-green;
      padding: 0 8px;
    }
  }
}

