@import "../variables";

.timer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: rgba($dark-gray, 0.08);

  p {
    color: $dark-gray
  }

  span {
    color: $dark-blue;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .timer-block {
    display: flex;
    align-items: center;
    gap: 24px;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &__line{
      display: block;
      width: 1px;
      height: 16px;
      background-color: $light-green;
    }
  }

  .progressbar-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__percent {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .no-dates-block {
    p {
      text-align: right;
    }
  }

  p {
    margin-bottom: 0;
  }
}

@media (max-width: 1250px) {
  .timer {
    .no-dates-block {
      width: 100%;
      margin-top: 24px;
    }
  }
}

@media (max-width: 1150px) {
  .timer {
    .progressbar-block {
      width: 100%;
      margin-top: 24px;
    }
  }
}

@media (max-width: 1024px) {
  .timer {
    .no-dates-block,
    .progressbar-block {
      width: auto;
      margin-top: 0;
    }
  }
}

@media (max-width: 800px) {
  .timer {
    .no-dates-block {
      width: 100%;
      margin-top: 24px;
    }
  }
}

@media (max-width: 768px) {
  .timer {
    .timer-block {
      gap: 16px;
    }
  }
}


@media (max-width: 599px) {
  .timer {
    flex-direction: column;
    gap: 24px;
    padding: 16px;

    .no-dates-block {
      margin-top: 0;
    }

    .timer-block {
      width: 100%;
      flex-wrap: wrap;

      p {
        width: 100%;
      }
    }

    .progressbar-block {
      align-self: flex-end;
    }
  }
}
