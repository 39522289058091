@import "../variables";

.accordion {
  background-color: $white;

  .accordion-item {
    border-top: 1px solid $light-gray;

    &:last-of-type {
      border-bottom: 1px solid $light-gray;
    }
  }

  .accordion-header {
    margin: 0;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 16px 56px 16px 16px;
      border: none;
      background-color: $white;
      cursor: pointer;

      &:after {
        content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M5 12.8604V11.3604H19V12.8604H5Z' fill='%23F48B01'/%3E%3C/svg%3E");
        display: block;
        position: absolute;
        height: 24px;
        width: 24px;
        right: 16px;
        top: calc(50% - 12px);
      }

      &.collapsed {
        &:after {
          content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M11.25 19.1104V12.8604H5V11.3604H11.25V5.11035H12.75V11.3604H19V12.8604H12.75V19.1104H11.25Z' fill='%23F48B01'/%3E%3C/svg%3E");
        }
      }
    }

    &__block-title {
      display: flex;
      width: calc(100% - 24px);
    }

    &__count {
      display: block;
      color: $dark-blue;
      height: 24px;
      width: 24px;
      flex-shrink: 0;
    }

    &__title {
      color: $dark-blue;
      font-weight: 600;
      margin: 0 8px 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__duration {
      flex-shrink: 0;
      color: $dark-gray;
      font-weight: 300;
    }
  }

  .accordion-collapse {
    height: 0;
    overflow: hidden;

    &.show {
      height: auto;
      overflow: visible;
    }

    &.collapsing {
      transition: height .35s ease;
    }

    .accordion-body {
      padding: 24px 48px 0;

      p, div, ul {
        margin-bottom: 24px;
      }

      ul {
        list-style: none;

        li {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='4' width='4'%3E%3Ccircle cx='2' cy='2' r='2' fill='%2348A9A6' /%3E%3C/svg%3E");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 4px;
            width: 4px;
            margin-right: 10px;
          }
        }
      }

      h5 {
        margin-bottom: 10px;
        color: $dark-blue;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 480px) {
  .accordion {
    .accordion-header {
      &__block-title {
        flex-direction: column;
      }

      &__title {
        white-space: normal;
      }

      &__duration {
        text-align: left;
        margin-left: 8px;
      }
    }
  }
}
