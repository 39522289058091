@import "../variables";

.services-page {
  section {
    padding: 40px 80px;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: $dark-blue;
    margin-bottom: 40px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .service-block {
      background-color: $dark-blue;
    }
  }
}

@media (max-width: 1024px) {
  .services-page {
    section {
      padding: 40px;
    }

    &__block {
      flex-wrap: wrap;

      .service-block {
        width: calc(50% - 10px);
      }
    }
  }
}

@media (max-width: 480px) {
  .services-page {
    &__block {
      .service-block {
        width: 100%;
      }
    }

    section {
      padding: 40px 24px;
    }
  }
}
