@import "../variables";

.paragraphs {
  border-radius: 8px;

  h3 {
    color: $dark-blue;
  }

  h3, p {
    margin-bottom: 24px;
  }

  .custom-paragraph {
    margin-bottom: 16px;
  }

  .custom-paragraph:last-of-type {
    margin-bottom: 24px;
  }

  &__button {
    padding-top: 24px;
    display: flex;
  }

  &.dark {
    padding: 40px;
    background-color: $dark-blue;

    h3, p, .custom-paragraph {
      color: $white;
    }

    svg path {
      fill: $light-green;
    }
  }
}

@media (max-width: 480px) {
  .paragraphs {
    &.dark {
      padding: 24px;
    }
  }
}
