@import "../variables";

.footer {
  padding: 40px 80px 8px;

  &-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 40px;
  }

  &__contact {
      width: calc((100% - 24px) / 2);
    /*width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;*/
    

    &-link {
      display: inline-block;
      margin-bottom: 16px;
    }
  }

  &__messengers-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    a:hover {
      path {
        fill: $green;
      }
    }
  }

  &__policy {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;

    p, span, a {
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
    }

    p, a {
      white-space: nowrap;
    }
  }

  &__partner {
    width: calc((100% - 24px) / 2);

    & > p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;
    }

    &-block {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px 40px;
    img{
        width: auto;
        height: 24px;
      }
    }

    &-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      &-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 124px;
        width: 124px;
        padding: 12px;
        border-radius: 8px;

        img {
          width: 80px;
          margin-bottom: 12px;
          height: auto;
        }

        h3 {
          font-size: 16px;
          line-height: 14px;
          font-weight: 600;

          &.green {
            color: $light-green;
          }

          &.orange {
            color: $orange;
          }
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }

        span {
          display: flex;
          align-items: flex-end;
          flex-grow: 1;
          font-size: 12px;
          line-height: 130%;
          font-weight: 300;
        }

        &.large-img {
          img {
            width: 100%;
          }
        }
      }

      &.dark {
        .footer__partner-cards-item {
          border: 2px solid $dark-blue;

          p {
            color: $dark-blue;
          }

          span {
            color: $dark-blue;
          }
        }
      }

      &.light {
        .footer__partner-cards-item {
          border: 2px solid $white;

          p {
            color: $white;
          }

          span {
            color: $white;
          }
        }
      }
    }
  }

  &.dark {
    background-color: $dark-blue;

    p, a, span {
      color: $white;
    }
  }

  &.light {
    background: none;

    p, a, span {
      color: $dark-blue;
    }

    a {
      font-weight: 400;
    }

    a {
      path {
        fill: $dark-blue;
      }

      &:hover {
        path {
          fill: $green;
        }
      }
    }
  }

  &.align-left {
    .section-wrapper {
      padding-right: 380px;
    }
  }
}

@media (max-width: 1120px) {
  .footer {
    &.align-left {
      .footer__partner {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    padding: 40px 40px 8px;

    &.align-left {
      .section-wrapper {
        padding-right: 0;
      }

      .footer__partner {
        width: calc((100% - 24px) / 2);
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &-block {
      gap: 40px;
    }

    &__contact {
      width: 100%;
    }

    &__partner {
      width: 100% !important;

      &-block {
        gap: 16px 40px;
      }
    }
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 40px 24px 8px;

    &__partner {
      p {
        margin-bottom: 16px;
      }
    }
  }
}
