@import "../variables";

.page-nav {
  width: 100%;

  &__links {
    position: relative;
    margin-bottom: 40px;

    &-item {
      list-style: none;

      a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 12px 0 12px 22px;
        border-left: 2px solid transparent;
        font-weight: 300;

        &:hover {
          background-color: rgba(143, 191, 224, 0.10);
          border-color: $dark-blue;
        }

        &.active {
          color: $dark-blue;
          border-color: $dark-blue;
        }
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $light-gray;
      z-index: -1;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
