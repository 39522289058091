@import "../variables";

.thank-you__page {
  &-main {
    max-width: 550px;
    margin: 20px auto;

    h2 {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 768px) {
  .thank-you__page {
    &-main {
      max-width: 384px;
      margin: 46px auto;
    }
  }
}


@media (max-width: 480px) {
  .thank-you__page {
    &-main {
      max-width: none;
      margin: 0;

      h2 {
        max-width: 272px;
      }
    }
  }
}
