@import "../variables";

.contact-form {
  &__input {
    margin-bottom: 24px;
  }

  input, textarea {
    display: block;
    width: 100%;
    padding: 16px 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $light-gray;

    &:focus-visible {
      outline: 0;
    }

    &:hover {
      border-color: $orange;
    }

    &:focus {
      border-color: $light-green;
    }

    &.danger {
      border-color: $red;
    }
  }

  textarea {
    resize: none;
  }

  .input-error {
    margin-top: 4px;
    color: $red;
    font-size: 14px;
    line-height: 22px;
  }

  .dropdown {
    margin-bottom: 24px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $light-gray;

    &:hover {
      border-color: $orange;
    }

    .btn {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 56px;
      padding: 16px 32px 16px 0;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:after {
        position: absolute;
        right: 0;
        top: 16px;
      }
    }

    .dropdown-menu {
      width: 100%;
      transform: translate3d(0px, 53px, 0px) !important;
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .contact-form__input {
      width: 50%;
    }
  }

  .checkbox-block {
    a {
      color: $orange;

      &:hover {
        color: $green;
      }
    }
  }

  .nav-button {
    margin-top: 48px;
  }

  .dropzone-block {
    padding-top: 12px;
    margin-bottom: 24px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      margin-bottom: 8px;

      button {
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        cursor: pointer;

        svg {
          fill: $dark-gray;
        }

        &:hover {
          svg {
            fill: $orange;
          }
        }
      }
    }

    &__error {
      display: block;
      color: $red;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding-top: 4px;
    }

    .dropzone {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 120px;
      padding: 0 20px;
      border: 1px solid $light-gray;
      border-radius: 10px;

      p {
        text-align: center;
        color: rgba($dark-gray, 0.5) !important;
      }

      img {
        margin-bottom: 12px;
        height: 40px;
        width: 40px;
      }

      &__file {
        color: rgba($dark-gray, 0.5);
        text-align: center;
      }
    }
  }

  &.light {
    input, textarea {
      color: $dark-gray;

      &::placeholder {
        color: $dark-gray;
      }
    }

    .dropdown {
      color: $dark-gray;

      .btn {
        color: $dark-gray;

        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 15.4L6 9.4L7.075 8.325L12 13.25L16.925 8.325L18 9.4L12 15.4Z' fill='%234B4E6D'/%3E%3C/svg%3E");
        }
      }
    }

    p {
      color: $dark-gray;
    }

    .checkbox-block {
      label, span {
        color: $dark-gray;
      }
    }
  }

  &.dark {
    input, textarea {
      color: $white;

      &::placeholder {
        color: $white;
      }
    }

    .dropdown {
      color: $white;

      .btn {
        color: $white;

        &:after {
          content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M12 16.3943L6 10.3943L7.075 9.31934L12 14.2443L16.925 9.31934L18 10.3943L12 16.3943Z' fill='white'/%3E%3C/svg%3E");
        }
      }
    }

    p {
      color: $white;
    }

    .checkbox-block {
      label, span {
        color: $white;
      }
    }
  }
}
