@import "../variables";

.carousel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  h3 {
    padding-right: 16px;
    color: $dark-blue;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 56px;
    flex-shrink: 0;

    &-prev,
    &-next {
      height: 24px;
      width: 24px;
      border: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      cursor: pointer;
    }

    &-prev {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19.9999 11.0001V13.0001H7.99991L13.4999 18.5001L12.0799 19.9201L4.15991 12.0001L12.0799 4.08008L13.4999 5.50008L7.99991 11.0001H19.9999Z' fill='%234B4E6D'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19.9999 11.0001V13.0001H7.99991L13.4999 18.5001L12.0799 19.9201L4.15991 12.0001L12.0799 4.08008L13.4999 5.50008L7.99991 11.0001H19.9999Z' fill='%23F48B01'/%3E%3C/svg%3E");
        background-color: transparent;
      }
    }

    &-next {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4 11.0001V13.0001H16L10.5 18.5001L11.92 19.9201L19.84 12.0001L11.92 4.08008L10.5 5.50008L16 11.0001H4Z' fill='%234B4E6D'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4 11.0001V13.0001H16L10.5 18.5001L11.92 19.9201L19.84 12.0001L11.92 4.08008L10.5 5.50008L16 11.0001H4Z' fill='%23F48B01'/%3E%3C/svg%3E");
        background-color: transparent;
      }
    }
  }
}

.carousel-root {
  .carousel-slider {
    overflow: visible !important;
  }

  .slide {
    padding: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(75, 78, 109, 0.08);

    .carousel-item {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 40px;

      &__img {
        height: 152px;
        width: 152px;
        border-radius: 50%;
        flex-shrink: 0;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__info {
        width: 100%;
        height: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-title {
          color: $dark-blue;
        }

        &-text {
          color: rgba(75, 78, 109, 0.50);
        }

        &-description {
          margin: 24px 0 8px;
        }

        &-page {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .carousel-root {
    .slide {
      .carousel-item {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 480px) {
  .carousel-root {
    .slide {
      padding: 40px 24px 24px;

      .carousel-item {
        flex-direction: column;

        &__img {
          align-self: center;
        }
      }
    }
  }
}
