@import "../variables";

.contacts-page {
  &__main {
    padding-top: 40px;

    h2 {
      margin-bottom: 24px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: calc(50% - 10px);
    }
  }

  &__form {
    padding-top: 40px;
    background-color: $dark-blue;

    h2 {
      margin-bottom: 0;
      color: $white;

      & + p {
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: $light-gray;
      }
    }

    .contact-form {
      width: calc(50% - 10px);
    }
  }
}

@media (max-width: 1024px) {
  .contacts-page {
    &__main {
      &-info {
        width: 70%;

        .info-block {
          gap: 16px;

          h3 {
            margin-bottom: 4px;
          }
        }
      }
    }

    &__form {
      .contact-form {
        width: 70%;
      }
    }
  }
}

@media (max-width: 768px) {
  .contacts-page {
    &__main {
      &-info {
        width: 100%;
      }
    }

    &__form {
      .contact-form {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .contacts-page {
    section {
      padding: 40px 24px 80px;
    }

    &__main {
      &-info {
        .info-block {
          text-align: center;
        }
      }
    }
  }
}
