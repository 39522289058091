@import "../variables";

.custom-link {
  color: $orange;
  display: inline-flex;
  align-items: center;

  svg {
    padding-left: 8px;
  }

  &:hover {
    color: #0D663B;

    path {
      fill: #0D663B;
    }
  }
}
