@import "../variables";

.about-page {
  &__main {
    padding-top: 40px;

    p:not(:last-of-type) {
      margin-bottom: 24px;
    }

    &-info {
      margin-bottom: 40px;
      min-height: 305px;
    }

    &-img {
      float: right;
      margin: 0 0 20px 115px;
      width: 455px;

      img {
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  &__services {
    background-color: $dark-blue;

    h2 {
      color: $white;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__work {
      padding-top: 10px;
    p:not(:last-of-type) {
      margin-bottom: 24px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 740px;
      margin: 80px auto 40px;
      box-sizing: border-box;
    }
  }

  &__achievements {
    background-color: $green;

    * {
      color: $white;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      background-color: rgba(255, 255, 255, 0.08);
      border-radius: 4px;

      div {
        width: calc(100% / 3);
        padding: 8px 20px 24px;
      }

      h3 {
        font-size: 48px;
        font-weight: 600;
        line-height: 150%;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.60);
      }
    }
  }

  &__experts {
    &-block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      margin: 40px 0;
    }

    &-button {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 1200px) {
  .about-page {
    &__main {
      &-img {
        margin: 0 0 20px 60px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .about-page {
    &__main {
      &-info {
        min-height: 268px;
      }

      &-img {
        width: 400px;
        margin: 0 0 20px 40px;
      }
    }

    &__services {
      &-block {
        flex-wrap: wrap;

        .service-block {
          width: calc(50% - 10px);
        }
      }
    }

    &__experts {
      &-block {
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 900px) {
  .about-page {
    &__main {
      &-info {
        min-height: 228px;
      }

      &-img {
        width: 340px;
        margin: 0 0 20px 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  .about-page {
    &__main {
      &-info {
        display: flex;
        flex-direction: column-reverse;
        min-height: auto;
      }

      &-img {
        float: none;
        width: 320px;
        margin: 24px 0 0;
      }
    }

    &__work {
      img {
        padding: 0 64px;
      }
    }
  }
}

@media (max-width: 600px) {
  .about-page {
    &__achievements {
      &-block {
        gap: 0;

        div {
          h3 {
            font-size: 40px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .about-page {
    section {
      padding: 80px 24px;
    }

    section.about-page__main {
      padding-top: 40px;
    }

    &__main {
      &-img {
        width: 100%;
      }
    }

    &__services {
      &-block {
        .service-block {
          width: 100%;
        }
      }
    }

    &__work {
      img {
        padding: 0;
      }
    }

    &__achievements {
      &-block {
        flex-wrap: wrap;
        gap: 8px;

        div {
          width: 100%;

          h3 {
            font-size: 48px;
          }
        }
      }
    }

    &__experts {
      &-block {
        flex-direction: column;
      }
    }
  }
}
