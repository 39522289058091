@import "../variables";

.home-page {
  &__main {
    display: flex;
    justify-content: center;
    align-items: center;

    &-block {
      width: 100%;
      max-width: 692px;
      margin: 0 auto;
      padding: 80px 0;
    }

    h1 {
      margin-bottom: 8px;
    }

    &-description {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      margin-bottom: 32px;
      color: $dark-gray;
    }

    .nav-tabs {
      margin-bottom: 32px;
    }

    &-link {
      display: flex;
      justify-content: center;
    }
  }

  &__about {
    background-color: $dark-blue;

    h2, p {
      color: $white;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-info {
      max-width: 645px;
      margin-right: 24px;

      p {
        margin-bottom: 24px;

        span {
          color: $orange;
        }
      }
    }

    &-statistics {
      width: 265px;
      background-color: rgba($light-gray, 8%);
      flex-shrink: 0;
      border-radius: 4px;

      div {
        padding: 8px 20px 24px;

        &:first-of-type,
        &:nth-of-type(2) {
          margin-bottom: 8px;
        }

        p:first-of-type {
          font-size: 48px;
          font-weight: 600;
          line-height: 150%;
        }

        p:last-of-type {
          font-size: 14px;
          line-height: 22px;
          color: $light-gray;
        }
      }
    }

    .custom-link {
      justify-content: flex-start;
    }
  }

  &__services {
    background-color: rgba($light-gray, 0.24);

    &-block {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    &-item {
      flex-basis: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 40px 24px 24px;
      background-color: $white;
      border-radius: 8px;

      &-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        margin: 0 auto 16px;
        border-radius: 100px;
        border: 1px solid $light-blue;
      }

      h3 {
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        margin-bottom: 16px;
      }

      &-link {
        flex-grow: 100;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &__form {
    background-color: $dark-blue;

    .section-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    &-block, form {
      width: 50%;
    }

    h2 {
      margin-bottom: 0;
      color: $white;

      & + p {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: $light-gray;

        svg {
          margin-left: 8px;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .home-page {
    &__services {
      &-block {
        flex-wrap: wrap;
        gap: 24px;
      }

      &-item {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .home-page {
    &__main {
      &-block {
        padding: 0;
      }

      h1 {
        word-spacing: 1000px;
      }
    }

    &__about {
      &-block {
        flex-wrap: wrap;
        gap: 40px;
      }

      &-info {
        margin-right: 0;
        width: 100%;
        max-width: none;
      }

      &-statistics {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;

        div {
          margin-bottom: 22px !important;
        }
      }
    }

    &__form {
      .section-wrapper {
        flex-wrap: wrap;
        gap: 24px;
      }

      &-block, form {
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .home-page {
    &__about {
      &-statistics {
        gap: 0;

        div {
          p:first-of-type {
            font-size: 40px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    &__main {
      h1 {
        font-size: 48px;
        line-height: 150%;
      }

      &-description {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__about {
      &-statistics {
       flex-wrap: wrap;
        gap: 8px;

        div {
          width: 100%;
          margin-bottom: 0 !important;

          p:first-of-type {
            font-size: 48px;
          }
        }
      }
    }

    &__form {
      p svg {
        display: none;
      }
    }
  }
}
