@import "../variables";

.chips {
  &-technologies-block {
    margin-top: 24px;
    padding: 24px;
    background-color: rgba($dark-gray, 0.08);
    border-radius: 8px;

    p {
      margin-bottom: 16px;
    }

    span {
      color: rgba($dark-gray, 0.50)
    }
  }
}
