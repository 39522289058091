.terms-and-policy__page {
  section {
    padding: 40px 80px 0;

    &:last-of-type {
      padding: 40px 80px;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  p, h3, ul {
    margin-bottom: 16px;
  }

  div {
    padding-bottom: 0px;
  }
}

@media (max-width: 1024px) {
  .terms-and-policy__page {
    section {
      padding: 40px 40px 0;

      &:last-of-type {
        padding: 40px;
      }
    }
  }
}

@media (max-width: 480px) {
  .terms-and-policy__page {
    section {
      padding: 40px 24px 0;

      &:last-of-type {
        padding: 40px 24px;
      }
    }
  }
}
