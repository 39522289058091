$white: #fff;
$dark-blue: #2b3441;
$light-blue: #8FBFE0;
$dark-gray: #4B4E6D;
$light-gray: #DDDDDD;
$orange: #7eca35;
$red: #F97370;
$green: #7788A0;
$light-green: #84DCC6;
$black: #161616;
