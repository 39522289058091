@import "../variables";

.react-calendar {
  position: relative;
  width: 440px;
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;

  &__navigation {
    &__label {
      margin-bottom: 16px;
      border: none;
      background-color: transparent;

      span {
        color: $green;
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__arrow {
      position: absolute;
      top: 26px;
      height: 24px;
      width: 24px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &__next-button {
      right: 24px;
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M4 11.1104V13.1104H16L10.5 18.6104L11.92 20.0304L19.84 12.1104L11.92 4.19043L10.5 5.61043L16 11.1104H4Z' fill='%234B4E6D'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M4 11.1104V13.1104H16L10.5 18.6104L11.92 20.0304L19.84 12.1104L11.92 4.19043L10.5 5.61043L16 11.1104H4Z' fill='%23F48B01'/%3E%3C/svg%3E");
      }

      &[disabled],
      &[disabled]:hover {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M4 11.1104V13.1104H16L10.5 18.6104L11.92 20.0304L19.84 12.1104L11.92 4.19043L10.5 5.61043L16 11.1104H4Z' fill='%234B4E6D' fill-opacity='0.5'/%3E%3C/svg%3E");
      }
    }

    &__prev-button {
      right: 56px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M20.0002 11.1104V13.1104H8.00016L13.5002 18.6104L12.0802 20.0304L4.16016 12.1104L12.0802 4.19043L13.5002 5.61043L8.00016 11.1104H20.0002Z' fill='%234B4E6D'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M20.0002 11.1104V13.1104H8.00016L13.5002 18.6104L12.0802 20.0304L4.16016 12.1104L12.0802 4.19043L13.5002 5.61043L8.00016 11.1104H20.0002Z' fill='%23F48B01'/%3E%3C/svg%3E");
      }

      &[disabled],
      &[disabled]:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M20.0002 11.1104V13.1104H8.00016L13.5002 18.6104L12.0802 20.0304L4.16016 12.1104L12.0802 4.19043L13.5002 5.61043L8.00016 11.1104H20.0002Z' fill='%234B4E6D' fill-opacity='0.5'/%3E%3C/svg%3E");
      }
    }

    &__next2-button,
    &__prev2-button {
      display: none;
    }
  }

  &__month-view__weekdays {
    padding: 4px 8px;
    background-color: $dark-blue;
    border-radius: 4px;
    gap: 16px;

    &__weekday {
      padding: 0 !important;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto !important;

      abbr {
        font-weight: 500;
        color: $white;
        text-decoration: none;
        text-transform: capitalize !important;
      }
    }
    &__weekday--weekend {
      abbr {
        color: $orange;
      }
    }
  }

  &__month-view__days {
    &__day {
      border: none;
      background-color: transparent;
      margin-top: 8px;

      abbr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: $dark-gray;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
      }

      &.highlight {
        abbr {
          border-radius: 50%;
          border: 2px solid $orange;
        }
      }

      &[disabled] {
        abbr {
          color: $light-gray;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .react-calendar {
    position: relative;
    width: 232px;
    padding: 0;

    &__month-view__weekdays {
      gap: 8px;

      &__weekday {
        height: 24px;
        width: 24px;

        abbr {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    &__month-view__days__day {
      margin-top: 16px;

      abbr {
        width: 24px;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      &.highlight abbr {
        border: 1px solid #F48B01;
      }
    }

    &__navigation__next-button {
      right: 0;
      top: 2px;
    }

    &__navigation__prev-button {
      right: 32px;
      top: 2px;
    }
  }
}
