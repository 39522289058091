@import "../variables";

header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;
  padding: 16px 80px;
  background-color: $white;
  z-index: 10;

  * {
    font-weight: 300;
  }

  .section-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .logo-link {
    display: block;
    width: 70px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav-buttons,
  .close-burger {
    display: none;
  }

  nav {
    display: flex;
    justify-content: right;

    & > a,
    .language-dropdown-block,
    .services-dropdown {
      margin-left: 24px;
    }

    & > a:not(.active):hover,
    .dropdown .btn:hover,
    .dropdown .nav-link:hover,
    .dropdown.show .btn,
    .dropdown.show .nav-link {
      color: $orange;
    }

    .dropdown .nav-link:hover,
    .dropdown .btn:hover,
    .dropdown.show .btn,
    .dropdown.show .nav-link {
      &:after {
        content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 15.4L6 9.4L7.075 8.325L12 13.25L16.925 8.325L18 9.4L12 15.4Z' fill='%237ECA35'/%3E%3C/svg%3E");
      }
    }

    .language-dropdown-block {
      position: relative;
      padding-left: 25px;

      &.mobile {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
        width: 1px;
        height: 16px;
        background-color: $orange;
      }

      .dropdown-menu {
        transform: translate3d(73px, 26px, 0px) !important;
        width: 122px;
      }
    }

    .services-dropdown {
      .dropdown-menu {
        transform: translate3d(-6px, 26px, 0px) !important;
        width: 218px;
      }

      &.active {
        .dropdown-toggle {
          color: $dark-blue;
          font-weight: 600;

          &:after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 15.4L6 9.4L7.075 8.325L12 13.25L16.925 8.325L18 9.4L12 15.4Z' fill='%23366092'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  header {
    padding: 16px 40px;
  }
}

@media (max-width: 768px) {
  header {
    .nav-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;

      &__contacts {
        background-color: transparent;
        color: $dark-gray;
        border: 1px solid $orange;
        padding: 7px 23px;

        &.active {
          color: $dark-gray;
          font-weight: 300;
        }

        &:hover {
          background-color: transparent;
          border: 1px solid $green;
          color: $green;
        }
      }
    }

    nav {
      min-height: 100vh;
      min-height: 100dvh;
      display: none;
      flex-direction: column;
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      background-color: $white;
      padding: 0 40px 40px;
      box-sizing: border-box;
      z-index: 15;

      .close-burger {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 0;
        height: 72px;
        box-sizing: border-box;
        margin-bottom: 32px;
      }

      a, .dropdown button {
        padding: 8px 0;
        margin: 0;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
      }

      .language-dropdown-block {
        display: none;
        margin: 0;
        padding: 0;

        .dropdown {
          button {
            height: 68px;
            padding: 8px 0 0;
          }

          .dropdown-menu {
            a:last-of-type {
              padding-bottom: 16px;
            }
          }
        }

        &.mobile {
          display: block;

          & + a {
            padding-top: 16px;
          }
        }

        &:before {
          width: 100%;
          height: 1px;
          left: 0;
          top: 8px;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          background-color: $orange;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
        }
      }

      &.open-mobile-nav {
        display: flex;
      }

      .dropdown {
        margin-left: 0;

        .dropdown-menu {
          position: relative !important;
          transform: none !important;
          width: 100% !important;
          inset: 0 !important;
          background-color: transparent;
          box-shadow: none;
          padding: 0;

          a {
            padding: 8px 0 8px 24px;
            color: $dark-gray;
            position: relative;

            &:hover, &.active  {
              background-color: transparent;
            }

            &.active  {
              &:after {
                display: none;
              }
            }

            &:before {
              content: "";
              width: 1px;
              height: 28px;
              display: block;
              position: absolute;
              left: 0;
              top: 8px;
              background-color: $light-gray;
            }
          }
        }

        .nav-link, .btn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            color: $dark-gray;
          }

          &:after,
          &:hover:after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M11.25 19V12.75H5V11.25H11.25V5H12.75V11.25H19V12.75H12.75V19H11.25Z' fill='%234B4E6D'/%3E%3C/svg%3E") !important;
          }

          &.show:after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M5 12.75V11.25H19V12.75H5Z' fill='%234B4E6D'/%3E%3C/svg%3E") !important;
          }
        }
      }

      .dropdown.show .nav-link,
      .dropdown.show .btn,
      a {
        color: $dark-gray !important;

        &:hover {
          color: $dark-gray;
        }
      }

      .services-dropdown.active .nav-link,
      a.active {
        color: $dark-blue !important;
        font-weight: 600 !important;
      }
    }

    .close-burger__button,
    .nav-buttons__burger {
      display: block;
      height: 32px;
      width: 32px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  header {
    padding: 16px;

    .close-burger {
      margin: 0 -8px 40px;
    }

    nav {
      padding: 0 24px 40px;

      a, .dropdown button {
        font-size: 16px;
        line-height: 24px;
      }

      .dropdown .dropdown-menu a {
        &:before {
          height: 24px;
        }
      }

      .language-dropdown-block {
        .dropdown {
          button {
            height: 64px;
          }
        }
      }
    }
  }
}
