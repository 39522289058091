@import "../variables";

.page-with-form {
  section {
    padding: 40px 80px 80px;
  }

  h2 {
    margin-bottom: 0;
  }

  h2 + p {
    color: rgba($dark-gray, 0.50);
  }

  .contact-form {
    margin-top: 20px;
    width: calc(50% - 10px);
  }
}

@media (max-width: 1024px) {
  .page-with-form {
    section {
      padding: 40px 40px 80px;
    }

    .contact-form  {
      width: 70%;
    }
  }
}

@media (max-width: 768px) {
  .page-with-form {
    .contact-form  {
      width: 100%;
    }
  }
}

@media (max-width: 488px) {
  .page-with-form {
    section {
      padding: 40px 24px 80px;
    }
  }
}
