@import "../variables";

.project-block {
  display: flex;
  align-items: flex-start;

  &__img {
    flex-shrink: 0;

    img {
      width: 100%;
      border-radius: 8px;
      box-shadow: 16px 16px 0 0 $light-gray, 0 0 10px 0 rgba(0, 0, 0, 0.24);
    }
  }

  &__subtitle {
    color: rgba($dark-gray, 50%);
  }

  &__description {
    margin: 16px 0;
  }

  &.large {
    gap: 115px;

    .project-block__img {
      width: 455px;
    }
  }

  &.small {
    gap: 44px;

    .project-block__img {
      width: 265px;
    }
  }
}

@media (max-width: 1024px) {
  .project-block {
    &.large {
      gap: 80px;

      .project-block__img {
        width: 320px;
      }
    }
  }
}

@media (max-width: 768px) {
  .project-block {
    flex-wrap: wrap;

    &.large {
      gap: 40px;
    }

    &.small {
      gap: 40px;

      .project-block__img {
        width: 320px;
      }
    }
  }
}

@media (max-width: 480px) {
  .project-block {
    &.large, &.small {
      .project-block__img {
        width: 200px;
      }
    }
  }
}
