.projects {
  display: flex;
  flex-direction: column;

  &.large {
    gap: 64px;
  }

  &.small {
    gap: 80px;
  }
}
