@import "../variables";

.info-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  width: calc(100% - 48px);
  padding: 24px;
  border-radius: 8px;

  &.light {
    background-color: rgba($dark-gray, 8%);
  }

  &.dark {
    background-color: $dark-blue;

    .info-block__text p {
      color: $white;
    }

    h3 {
      color: $light-green;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 1px solid $light-blue;

    img {
      width: 32px;
      height: 32px;
    }
  }

  &__text {
    flex-grow: 1;
  }

  h3 {
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .info-block {
    flex-direction: column;
    align-items: center;
    padding: 40px 24px 24px;

    h3 {
      text-align: center;
    }
  }
}
